import React from "react";
import Lottie from "lottie-react";

import "./App.css";
import Loader from "./images/loader.json";

let baseApiURL = "https://app.starstake.com";

const hrefArr = window.location.href.split("/");
const urlParams = hrefArr[hrefArr.length - 1].split("?");
const accessNftId = urlParams[0];
if (urlParams[1]) {
  let env = "qa";
  if (urlParams[1].includes("dev=")) env = "dev";
  baseApiURL = `https://${env}-gateway-service.starstake.com`;
}

function App() {
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    if (!data?.code) {
      fetch(baseApiURL + "/certificate-code/" + accessNftId)
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 200 && data.data) setData(data.data);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  if (!data?.code)
    return (
      <main className="access-nft-loader-container">
        <Lottie animationData={Loader} loop={true} />
      </main>
    );

  return (
    <main>
      <div className="access-nft-certificate-code-box">
        <div className="access-nft-main-container">
          <div className="access-nft-img-container">
            <img src={data.accessNFTImagePath} alt="" />
          </div>
          <div className="access-nft-details-container">
            <div className="access-nft-details-arrow">
              <img src={require("./images/arrow-icon.svg").default} alt="" />
            </div>
            <h2>Claim your free NFT to earn rewards and join our brandclub!</h2>
            <p>
              Don't miss out on our exciting rewards program by grabbing your
              free Access NFT Certificate. Use the <strong>Access Code</strong>{" "}
              below for instant delivery of your NFT and access to exciting
              rewards inside our BrandClub on StarStake.
            </p>
          </div>
          <div className="access-nft-content-container">
            <div className="access-nft-code-container">
              <div className="access-nft-code">{data.code}</div>
              <div
                className="access-nft-copy-code"
                onClick={() => navigator.clipboard.writeText(data.code)}
              >
                <img src={require("./images/copy-icon.svg").default} alt="" />
                <div>
                  Copy <br />
                  Access Code
                </div>
              </div>
            </div>
            <p className="access-nft-sub-text">
              * Free StarStake account registration required
            </p>
            <div className="access-nft-steps-container">
              <div className="access-nft-step">
                <div className="access-nft-step-count">01</div>
                <div className="access-nft-step-details">
                  Copy your Access Code above
                </div>
              </div>
              <div className="access-nft-step">
                <div className="access-nft-step-count">02</div>
                <div className="access-nft-step-details">
                  Register on StarStake & enter your Access code
                </div>
              </div>
              <div className="access-nft-step">
                <div className="access-nft-step-count">03</div>
                <div className="access-nft-step-details">
                  Check your NFT for access to bonus products & perks
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="access-nft-bottom-container">
          <div className="access-nft-bottom-left-section">
            <img src={require("./images/snft-icon.svg").default} alt="" />
            Make sure to join our exclusive BrandClub on StarStake to
            participate in exciting reward promotions.
          </div>
          <a
            className="access-nft-bottom-right-section"
            href="https://starstake.com"
            target="_blank"
            rel="noreferrer"
          >
            Visit StarStake{" "}
            <img src={require("./images/visit-icon.svg").default} alt="" />
          </a>
        </div>
      </div>
    </main>
  );
}

export default App;
